'use strict'

###*
 # @ngdoc service
 # @name mundoBranding.factory:MundoBranding

 # @description

###
angular
  .module 'mundoBranding'
  .factory 'MundoBranding', [
    '$rootScope'
    ($rootScope) ->
      MundoBrandingBase = {}
      MundoBrandingBase.themes = {}
      MundoBrandingBase.activeTheme = null

      MundoBrandingBase.applyTheme = (theme) ->
        $rootScope.branding.theme = theme

      MundoBrandingBase.setActiveTheme = (theme) ->
        MundoBrandingBase.activeTheme = theme
        MundoBrandingBase.applyTheme theme

      MundoBrandingBase
  ]
